var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.prevPost || _vm.nextPost)?_c('v-row',{staticClass:"posts-nav-container justify-space-between align-center"},[(_vm.prevPost)?_c('v-col',{staticClass:"posts-nav",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
        name: _vm.routeName,
        params: { postName: _vm.prevPost.slug }
      }}},[_c('span',{staticClass:"primary--text d-flex align-center font-weight-bold text-uppercase"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$prev")]),_vm._v(_vm._s(_vm.$t("wordpress.previous"))+" ")],1)])],1):_vm._e(),(_vm.nextPost)?_c('v-col',{staticClass:"posts-nav",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
        name: _vm.routeName,
        params: { postName: _vm.nextPost.slug }
      }}},[_c('span',{staticClass:"primary--text d-flex align-center justify-end font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.$t("wordpress.next"))+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$next")])],1)])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }