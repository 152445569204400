<template>
  <v-row
    v-if="prevPost || nextPost"
    class="posts-nav-container justify-space-between align-center"
  >
    <v-col cols="6" class="posts-nav" v-if="prevPost"
      ><router-link
        :to="{
          name: routeName,
          params: { postName: prevPost.slug }
        }"
      >
        <span
          class="primary--text d-flex align-center font-weight-bold text-uppercase"
          ><v-icon color="primary">$prev</v-icon>{{ $t("wordpress.previous") }}
        </span>
      </router-link>
    </v-col>
    <v-col cols="6" class="posts-nav" v-if="nextPost"
      ><router-link
        :to="{
          name: routeName,
          params: { postName: nextPost.slug }
        }"
      >
        <span
          class="primary--text d-flex align-center justify-end font-weight-bold text-uppercase"
          >{{ $t("wordpress.next") }}
          <v-icon color="primary">$next</v-icon>
        </span>
      </router-link></v-col
    >
  </v-row>
</template>
<style lang="scss" scoped>
.posts-nav-container {
  .posts-nav {
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
}
</style>
<script>
import CMService from "~/service/cmService";
export default {
  name: "PostsNav",
  props: {
    postId: {
      type: Number,
      required: true
    },
    postType: {
      type: String,
      default: "post"
    }
  },
  data() {
    return {
      prevPost: null,
      nextPost: null
    };
  },
  computed: {
    routeName() {
      switch (this.postType) {
        case "post":
          return "NewsDetail";
        case "posts":
          return "NewsDetail";
        case "news":
          return "NewsDetail";
        case "approfondimento":
          return "ApprofondimentoDetail";
        case "video":
          return "VideoDetail";
        case "recipe":
          return "RecipeDetail";
        case "appuntino":
          return "RecipeDetail";
        case "mixology":
          return "RecipeDetail";
        case "faq":
          return "FaqiList";
        default:
          return "Page";
      }
    }
  },
  methods: {
    async fetchPosts() {
      if (this.postType === "post") {
        this.postType = "posts";
      }

      const nextPosts = await CMService.getNexPrevPosts(
        this.postType,
        this.postId
      );
      this.nextPost = nextPosts.length > 0 ? nextPosts[0] : null;

      const prevPosts = await CMService.getNexPrevPosts(
        this.postType,
        this.postId,
        false
      );
      this.prevPost = prevPosts.length > 0 ? prevPosts[0] : null;
    }
  },
  async mounted() {
    await this.fetchPosts();
  }
};
</script>
